import gsap from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";

gsap.registerPlugin(ScrollTrigger);

const skillElements = gsap.utils.toArray('.skill');

skillElements.forEach((skill) => {
    gsap.to(skill, {
        backgroundSize: '100%',
        ease: 'none',
        scrollTrigger: {
            trigger: skill,
            start: "center 75%",
            end: "center 20%",
            scrub: true
        }
      });
});

gsap.to(".contactButton", {
    fontSize: '3vw',
    borderRadius: '12px',
    ease: 'none',
    scrollTrigger: {
        trigger: ".about",
        start: "top top",
        end: "bottom 90%",
        scrub: true
    }
  });

window.addEventListener("resize", () => {
    ScrollTrigger.refresh();
});